export const initialState = {
  loading:false,
    about: '',
    headerData: '',
    numerology: [],
    numCategories: [],
    info: '',
    actions: [],
    advantages: [],
    counting: [],
    products: [],
    cartItems: [],
    num6: '',
    num7: '',
    num8: '',
    comments: [],
    viewProdId: "",
    certificates: [],
    gallery: [],
    rasshifrovki:     [
      {
        id: 1,
        num: 3,
        title: "Императрица",
        description: [
          "Вам необходимо, в первую очередь, опираться на интуицию (убирать голос разума и рассудка).",
          "Важно прислушиваться к ощущениям.",
          "Стремитесь к своему проекту, делу, бизнесу или руководству."
        ],
        properties: [
          "xорошо работать в сфере женщин и детей",
          "с благодарностью оплачивайте другим людям их труд",
          "научитесь доверять",
          "научитесь идти на компромиссы"
        ]
      },
      {
        id: 2,
        num: 4,
        title: "Император",
        description: [
          "Вам необходимо выбирать сферы контроля, порядка, где все четко, структурированно.",
          "Энергии императора соответствует государственная служба, создание своего бизнеса.",
          "Подойдет и сфера, где в окружении - мужчины.",
          "Если фриланс - важно, чтобы была самодисциплина."
        ],
        properties: [
          "отсутствие порядка ведет к блокированию денежного канала",
          "отсутствие цели ведет к блокированию денежного канала",
          "отсутствие дисциплины ведет к блокированию денежного канала"
        ]
      },
      {
        id: 3,
        num: 5,
        title: "Жрец, учитель",
        description: [
          "Вы - прекрасный наставник, учитель.",
          "Вы способны донести сложные вещи простым доступным языком.",
          "Вы умеете найти подход к любому человеку.",
          "Ваша задача - постоянно повышать квалификацию, получать новые знания и их передавать (по запросу).",
          "Хорошо добиться высокого уровня экспертности, обучать не учеников, а, например, учителей."
        ],
        properties: [
          "обязательно свои знания, навыки передавать",
          "важно обучать, а не поучать"
        ]
      },
      {
        id: 4,
        num: 6,
        title: "Влюбленные",
        description: [
          "Вам важно выбирать сердцем, следовать за душой.",
          "Любите свою работу и все, с чем связана реализация себя.",
          "Любовь к людям, к знаниям, к материалам, с которыми работаете.",
          "Деньги приходят через консультирование, создание красоты (как внутри человека, так и снаружи), психологию, коучинг.",
          "Творчество + коммуникации = успех"
        ],
        properties: [
          " важно не отказываться от мелких предложений, бонусов (потому как отказываясь от мелкого, не притянете и большего)"
        ]
      },
      {
        id: 5,
        num: 7,
        title: "Колесница",
        description: [
          "Вам важно ставить цели. Конкретные цели.",
          "Необходимо все детально расписывать и держать фокус. Вплоть до срока, даты, названия, бренда, цвета и т. п.",
          "Не бежать ради действия, а четко задавать себе курс движения.",
          "Вы рождены, чтобы ставить себе высокие цели, быть примером."
        ],
        properties: [
          "не должно быть воинственного настроя"
        ]
      },
      {
        id: 6,
        num: 8,
        title: "Справедливость",
        description: [
            "Вам важно зарабатывать в спокойном состоянии, соблюдая баланс.",
          "Не только отдавать, но и брать.",
          "Нужно просить, заявлять, формулировать то, что хотите.",
          "Просите у людей, у Вселенной!",
          "Вселенная должна знать, какие возможности Вам предоставлять для реализации ваших целей.",
          "Главное, фиксировать их и заявлять, требовать, просить."
        ],
        properties: [
          "часто люди - носители этой энергии не просят, но думают, что другие догадываются, что они хотят"
        ]
      },
      {
        id: 7,
        num: 9,
        title: "Отшельник, мудрец",
        description: [
          "К Вам деньги будут приходить через мудрость, анализ, умение докапываться до глубины, до истины.",
          "Ваша задача - передавать знания, таланты, умения (свой внутренний свет) миру.",
          "9 - число, с которого начинаются все знания.",
          "Самые лучшие преподаватели, педагоги, наставники, тренеры, коучи, психологи, медики - носители энергии 9."
        ],
        properties: [
          "важно проработать вопрос «деньги - не главное»",
          "нельзя покупать себе дешевое и экономить на себе",
          "убирать интеллектуальную гордыню («я знаю»)"
        ]
      },
      {
        id: 8,
        num: 10,
        title: "Колесо Фортуны",
        description: [
          "У Вас прекрасная сонастройка со Вселенной.",
          "Вы пришли в эту жизнь, чтобы пробовать все.",
          "Если «сидите в болоте» - срочно нужно выходить и творить.",
          "Вы - человек достаточно пластичный.",
          "Вам подходит все, что Вам интересно.",
          "И насколько Вам интересно то, чем Вы занимаетесь и что Вас окружает, настолько к Вам и притягивается изобилие."
        ],
        properties: [
          "обычно гениальные идеи приходят неожиданно"
        ]
      },
      {
        id: 9,
        num: 11,
        title: "Сила",
        description: [
          "У Вас деньги приходят тогда, когда соблюдается баланс «работа - отдых».",
          "Важно напоминать себе, что отдых важен и нужен.",
          "Физическая усталость блокирует Вашу денежную энергию."
        ],
        properties: [
          "важно следить за физическим состоянием, здоровьем",
          "важно брать отпуск не 3-4 дня, а минимум 2 недели",
          "важно нормально, сбалансировано питаться, и обойтись без пагубных привычек"
        ]
      },
      {
        id: 10,
        num: 12,
        title: "Повешенный",
        description: [
          "Вы видите мир под другим углом, у Вас уникальный взгляд на вещи.",
          "Процесс получения денег идет через процесс создания чего-то нового (может, усовершенствованного на базе уже существующего).",
          "Это может быть новый тренд, новый продукт, новая услуга, новая технология и т. д."
        ],
        properties: [
          "важно получать обратную связь",
          "важно брать деньги за свою работу",
          "важно научиться говорить «нет» и отходить от некомфортных людей, «халявщиков»"
        ]
      },
      {
        id: 11,
        num: 13,
        title: "Смерть",
        description: [
          "Ваша задача - научиться не держаться за старое и отпускать то, что себя уже изжило.",
          "Важно не допускать «день сурка».",
          "Постоянно интересуйтесь новым, внедряйте что-то новое, повышайте квалификацию, учитесь, узнавайте и применяйте всякие «фишечки»."
        ],
        properties: [
          "однообразие, рутина, застои блокируют Ваш денежный канал"
        ]
      },
      {
        id: 12,
        num: 14,
        title: "Умеренность",
        description: [
          "Вам очень важно работать в спокойном состоянии, нельзя спешить и форсировать события.",
          "Процесс получения денег идет от процесса создания чего-то с «0».",
          "При этом, замечательно, если будет участвовать при этом творчество + интуиция.",
          "Вы в потоке, когда что-то создаете.",
          "Вам очень важно идти за своим сердцем, а не за деньгами. Чтобы пела душа."
        ],
        properties: [
          "дед - лайны, сроки - ставить не рекомендуется",
          "нельзя себя заставлять"
        ]
      },
      {
        id: 13,
        num: 15,
        title: "Дьявол",
        description: [
          "Вам очень важно постоянно развиваться, расширять границы сознания",
          "Научитесь нормализовывать свои реакции - не взрывайтесь и не уходите в себя.",
          "Вам очень важно работать в удовольствие и быть на виду."
        ],
        properties: [
          "важно убрать осуждение других людей",
          "важно научиться быть наравне с  другими людьми"
        ]
      },
      {
        id: 14,
        num: 16,
        title: "Башня",
        description: [
          "Вам очень важно следить за целями.",
          "Зацикленность на материальном очень сильно стопорит Ваш денежный поток.",
          "Намеренно смещайте фокус от денег.",
          "И не бойтесь что-то закончить, чтобы выстроить с «0» (начать с «0»)."
        ],
        properties: [
          "отсутствие духовного развития также будет стопорить ваши денежные потоки"
        ]
      },
      {
        id: 15,
        num: 17,
        title: "Звезда",
        description: [
          "Ваша задача - быть признанным.",
          "Вам необходимо признание, публика, восхищение, отзывы.",
          "Необходимо показывать себя и свой творческий потенциал (выступления, презентации и т. п.)",
          "И, даже когда уже добьетесь определенного уровня успеха, старайтесь всегда поддерживать «костер» внутри себя."
        ],
        properties: [
          "важно не поймать «звездность»"
        ]
      },
      {
        id: 16,
        num: 18,
        title: "Луна",
        description: [
          "Вы рождены с высокой частотой восприятия.",
          "Ваша мысль - творит реальность.",
          "Поэтому, что подумал, то и проявляется.",
          "Важно чистить свои мысли, наполняться позитивом.",
          "Проработайте все ложные установки по поводу денег (большие деньги тяжело зарабатываются, все богатые - воры и т.д.)"
        ],
        properties: [
          "страх безденежья очень сильно мешает пробить финансовый потолок"
        ]
      },
      {
        id: 17,
        num: 19,
        title: "Солнце",
        description: [
          `Вы - прирожденный лидер. У Вас очень много внутренней силы и энергии.           И Вам важно этой энергией делиться.`,
          "Вы - Солнце. А Солнцу важно сиять и светить, поэтому публичность, внимание, аудитория, - это то, что Вам необходимо.",
          "Вы умеете раскрывать таланты других людей.",
          "Вы можете вести за собой, создавать рабочие места, давать людям расти и зарабатывать.",
          "Очень хорошо делать вклад в благое дело, заниматься благотворительностью."
        ],
        properties: [
          "чувство вины (в том числе, вины себя в чем - то) может очень сильно стопорить ваше денежное развитие."
        ]
      },
      {
        id: 17,
        num: 20,
        title: "Суд",
        description: [
          "Вам очень важно проанализировать Ваши отношения с родителями.",
          "Если есть какие-то ложные установки, которые Вам передали родители, касательно денег (все богатые - воры и т. д.) - важно проработать.",
          "Также, если есть обиды на родителей, или осуждение, то важно простить.",
          "Переработайте энергию осуждения - в энергию благодарности."
        ],
        properties: [
          "очень важно проработать все обиды и родительские установки о зарабатывании денег"
        ]
      },
      {
        id: 19,
        num: 22,
        title: "Шут",
        description: [
          "Для Вас очень важно создавать финансовую независимость.",
          "Вы - человек свободы.",
          "Сам себе хозяин, сам себе режиссер.",
          "Без сроков, без ограничений, без границ."
        ],
        properties: [
          "рамки, кредиты, ограничения, шаблоны - это то, что будет мешать Вам в увеличении денежных потоков"
        ]
      },
      {
        id: 19,
        num: 21,
        title: "Мир",
        description: [
          "Все слова, однокоренные с - мир - подходят Вам для реализации себя.",
          "Международные компании, мировые рынки, тенденции, тренды, передовые технологии.",
          "Работа в интернете.",
          "Работа с иностранцами  или заграницей.",
          "Работа с иностранными языками.",
          "Для Вас очень важно иметь такую работу, которая будет позволять путешествовать, в том числе, для Вас очень важно бывать заграницей."
        ],
        properties: [
          "отсутсвие путешествий (особенно заграницу) и отсутсвие расширения кругозора - блокируют Ваш денежный канал"
        ]
      }
    ],
    prodCount: 1,
    usluqaCount: 1,
    materials: [],
    models: [],
    types: [],
    viewGalleryId: '',
    viewCommentId: '',
    case: [],
    braclets: [],
    moneyBraclets:[],
    moneyPrice:'',
    totalPages:'',
    page:'',
    perPage:'',
    checkOutStatus:'',
    checkOutNum:''
}   