import React from 'react'
import img from './img/WhatsApp Image 2024-01-12 at 16.53.35.jpeg'

function Text() {
  return (
    <div className='text' style={{textAlign:'center'}}>
      <img src={img} />
    </div>
  )
}

export default Text